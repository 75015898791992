// src/components/Pricing.jsx

import React from "react";
import styled from "styled-components";
// Import react-vertical-timeline-component
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
// Import Icons
//import { StartIcon, UpdateIcon } from "../Elements/Icons/StartIcon"; // Adjust the path as needed
// Import Timeline Data
//import timelineData from "../data/timelineData";
// src/data/timelineData.js

//import StartImage from "../../assets/img/timeline/start.svg"; // Replace with actual paths
//import UpdateImage from "../../assets/img/timeline/update.svg"; // Replace with actual paths

const timelineData = [
  {
    id: 1,
    date: "January 2023",
    title: "Project Initiation",
    description:
      "The project was officially kicked off with initial planning and resource allocation.",
    icon: "start",
  },
  {
    id: 2,
    date: "March 2023",
    title: "Design Phase",
    description:
      "Completed the design phase, including wireframes and mockups for the user interface.",
    icon: "update",
  },
  {
    id: 3,
    date: "June 2023",
    title: "Development",
    description: "Began the development of core features and functionalities.",
    icon: "update",
  },
  {
    id: 4,
    date: "September 2023",
    title: "Testing",
    description:
      "Initiated the testing phase to identify and fix bugs and ensure quality.",
    icon: "update",
  },
  {
    id: 5,
    date: "December 2023",
    title: "Launch",
    description:
      "Successfully launched the project, making it available to users.",
    icon: "update",
  },
  // Add more events as needed
];

export default function Pricing() {
  return (
    <Wrapper id="timeline">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Project Timeline</h1>
            <p className="font13">
              Track the progress and key milestones of our projects over time.
            </p>
          </HeaderInfo>
          <TimelineContainer>
            <VerticalTimeline>
              {timelineData.map((event) => (
                <VerticalTimelineElement
                  key={event.id}
                  date={event.date}
                  iconStyle={{
                    background: event.icon === "start" ? "#0b093b" : "#f2b300",
                    color: "#fff",
                  }}
                  //  icon={event.icon === "start" ? <StartIcon /> : <UpdateIcon />}
                >
                  <h3 className="vertical-timeline-element-title">
                    {event.title}
                  </h3>
                  <p>{event.description}</p>
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
          </TimelineContainer>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
  background-color: #f9f9f9;
`;

const HeaderInfo = styled.div`
  margin-bottom: 50px;
  text-align: center;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const TimelineContainer = styled.div`
  .vertical-timeline-element-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .vertical-timeline-element-description {
    font-size: 1rem;
    color: #555;
  }
`;

// import React from "react";
// import styled from "styled-components";
// // Components
// import PricingTable from "../Elements/PricingTable";

// export default function Pricing() {
//   return (
//     <Wrapper id="pricing">
//       <div className="whiteBg">
//         <div className="container">
//           <HeaderInfo>
//             <h1 className="font40 extraBold">Check Our Pricing</h1>
//             <p className="font13">
//               Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
//               <br />
//               labore et dolore magna aliquyam erat, sed diam voluptua.
//             </p>
//           </HeaderInfo>
//           <TablesWrapper className="flexSpaceNull">
//             <TableBox>
//               <PricingTable
//                 icon="roller"
//                 price="$29,99/mo"
//                 title="Starter"
//                 text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
//                 offers={[
//                   { name: "Product Offer", cheked: true },
//                   { name: "Offer", cheked: true },
//                   { name: "Product Offer #2", cheked: false },
//                   { name: "Product", cheked: false },
//                   { name: "Product Offer", cheked: false },
//                 ]}
//                 action={() => alert("clicked")}
//               />
//             </TableBox>
//             <TableBox>
//               <PricingTable
//                 icon="monitor"
//                 price="$49,99/mo"
//                 title="Basic"
//                 text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
//                 offers={[
//                   { name: "Product Offer", cheked: true },
//                   { name: "Offer", cheked: true },
//                   { name: "Product Offer #2", cheked: true },
//                   { name: "Product", cheked: true },
//                   { name: "Product Offer", cheked: false },
//                 ]}
//                 action={() => alert("clicked")}
//               />
//             </TableBox>
//             <TableBox>
//               <PricingTable
//                 icon="browser"
//                 price="$59,99/mo"
//                 title="Golden"
//                 text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
//                 offers={[
//                   { name: "Product Offer", cheked: true },
//                   { name: "Offer", cheked: true },
//                   { name: "Product Offer #2", cheked: true },
//                   { name: "Product", cheked: true },
//                   { name: "Product Offer", cheked: true },
//                 ]}
//                 action={() => alert("clicked")}
//               />
//             </TableBox>
//           </TablesWrapper>
//         </div>
//       </div>
//     </Wrapper>
//   );
// }

// const Wrapper = styled.section`
//   width: 100%;
//   padding: 50px 0;
// `;
// const HeaderInfo = styled.div`
//   margin-bottom: 50px;
//   @media (max-width: 860px) {
//     text-align: center;
//   }
// `;
// const TablesWrapper = styled.div`
//   @media (max-width: 860px) {
//     flex-direction: column;
//   }
// `;
// const TableBox = styled.div`
//   width: 31%;
//   @media (max-width: 860px) {
//     width: 100%;
//     max-width: 370px;
//     margin: 0 auto
//   }
// `;
